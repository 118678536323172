import React, { useState, useEffect, useContext } from "react";

import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";

import ColorBox from "../Components/ColorBox";

import rgba2Alfa from "../Functions/rgba2Alfa";
import rgba2Hex from "../Functions/rgba2Hex";
import hex2Rgba from "../Functions/hex2Rgba";

const ShopLayout = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const [updating, setUpdating] = useState(false);
  const [reset, setReset] = useState(false);

  const [options, setOptions] = useState();
  var [layout, setLayout] = useState();
  // var [layout, setLayout] = useState();

  useEffect(() => {
    shopObject.getshopoptions();
  }, [controlObject.webshop, reset]);

  useEffect(() => {
    setOptions(shopObject.shopoptions);
    setLayout(JSON.parse(shopObject.shopoptions.layout));
    console.log("unparsed:", shopObject.shopoptions.layout);
    console.log("parsed:", JSON.parse(shopObject.shopoptions.layout));
  }, [shopObject.shopoptions]);

  function handleChange(e, index1, index2) {
    let tempcolors = layout[index1]["colors"].map((i) => i);
    let templayout = layout.map((i) => i);
    let hex = rgba2Hex(tempcolors[index2].rgba);
    let opac = rgba2Alfa(tempcolors[index2].rgba);

    const n = e.target.name;
    const value = e.target.value;
    if (n === "hex") {
      hex = value;
    }
    if (n === "opacity") {
      opac = validopacity(value);
    }
    tempcolors[index2].rgba = hex2Rgba(hex, opac);
    templayout[index1]["colors"][index2].rgba = tempcolors[index2].rgba;
    layout = templayout;
    setLayout(templayout);
    options.layout = templayout;
    setOptions(options);
    setUpdating(true);
  }

  function validopacity(op) {
    if (op < 0) {
      op = 0;
    }
    if (op > 1) {
      op = 1;
    }
    op = Math.round(op * 10, 1) / 10;
    return op;
  }

  function update() {
    options.layout = JSON.stringify(options.layout);
    shopObject.updateshopoptions(options);
    setUpdating(false);
  }

  return (
    <div>
      <div className="row">
        <div className="scsubtitle col-3">Select your colors</div>
        {updating && (
          <>
            <div className="scline col-3">
              <button
                className="btn btn-success btn-sm scaddbutton"
                onClick={() => update()}
              >
                Update
              </button>
              <button
                className="btn btn-secondary btn-sm scaddbutton"
                onClick={() => {
                  setReset(!reset);
                  setUpdating(false);
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
      <form>
        {layout &&
          layout.map((full, index1) => {
            return (
              <div className="row w-75 mx-auto" key={index1}>
                <div className="col-2 d-flex align-items-center fw-bold">
                  <div>{full.name}</div>
                </div>
                <div className="col-4">
                  {full.colors.map((col, index2) => {
                    return (
                      <ColorBox
                        col={col}
                        index1={index1}
                        index2={index2}
                        handleChange={handleChange}
                        key={index2}
                      />
                    );
                  })}
                </div>
                <div className="col-4 d-flex align-items-center fw-bold justify-content-center">
                  <div>
                    <div
                      className="slbox d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: full["colors"][0].rgba,
                      }}
                    >
                      <div
                        className=""
                        style={{ color: full["colors"][1].rgba }}
                      >
                        TEXT
                      </div>
                    </div>
                  </div>
                </div>
                <hr className=""></hr>
              </div>
            );
          })}
      </form>
    </div>
  );
};

export default ShopLayout;
