import React, { useState } from "react";

const EditProductOption = ({
  option,
  setToggler,
  loadOptions,
  setLoadOptions,
  setWorkingOptions,
}) => {
  const [productOption, setProductOption] = useState(option);

  let updateOption = async (prodOp) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/options/",
      {
        method: "PATCH",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: prodOp,
      }
    );
    if (resp.status === 200) {
      setToggler(null);
      setLoadOptions(!loadOptions);
      setWorkingOptions(false);
    } else {
      alert("something went wrong updating option");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setProductOption({ ...productOption, [fieldname]: value });
  };

  const optionSubmit = (e) => {
    e.preventDefault();
    const prodOp = new FormData();
    prodOp.append("id", productOption.id);
    prodOp.append("product", productOption.product);
    prodOp.append("webshop", productOption.webshop);
    prodOp.append("description", productOption.description);
    prodOp.append("price", productOption.price);
    prodOp.append("vat", productOption.vat);
    prodOp.append("selected", productOption.selected);
    updateOption(prodOp);
  };

  return (
    <div className="option-input">
      <form className="" onSubmit={(e) => optionSubmit(e)}>
        <div className="row">
          <label className="col-8">Description</label>
          <label className="col-4">Price</label>
        </div>
        <div className="row">
          <input
            className="col-7 ms-3"
            type="text"
            placeholder="Description"
            name="description"
            value={productOption.description}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,200}$"
            required
          />
          <div className="col-2">
            <strong>
              {(productOption.price * (1 + productOption.vat)).toFixed(2)}
            </strong>
          </div>
        </div>
        <div className="row">
          <label className="col-3 ms-3">Price</label>
          <label className="col-3">VAT</label>
        </div>
        <div className="row">
          <input
            className="col-3 ms-3"
            type="text"
            placeholder="Price"
            name="price"
            value={productOption.price}
            onChange={handler}
          />
          <input
            className="col-3"
            type="text"
            placeholder="Vat"
            name="vat"
            value={productOption.vat}
            onChange={handler}
          />
          <button
            className="border border-0 bg-transparent col-1 fs-4"
            type="submit"
          >
            <i className="bi bi-check"></i>
          </button>
          <button
            className="border border-0 bg-transparent col-1 fs-4"
            type="button"
            onClick={() => {
              setToggler(null);
              setWorkingOptions(false);
            }}
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProductOption;
