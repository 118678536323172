import React, { Fragment } from "react";
import GetDay from "./GetDay";

const PreOrderListing = ({ itemList, orderUpdateItem, accentuate }) => {
  return (
    <div>
      {itemList && itemList.length > 0 && (
        <div>
          {itemList.map((li, li_index) => {
            return (
              <div key={li_index} className="ocdatarow fs-7">
                <div className="occolm">
                  {accentuate ? (
                    <>
                      {new Date(
                        new Date(li.orderdate).setHours(0, 0, 0, 0)
                      ).getTime() <
                      new Date(new Date().setHours(0, 0, 0, 0)).getTime() ? (
                        <div className="fw-bold text-danger">
                          {<GetDay code={new Date(li.orderdate).getDay()} />}{" "}
                          {new Date(li.orderdate).getDate()}/
                          {new Date(li.orderdate).getMonth() + 1}/
                          {new Date(li.orderdate).getFullYear()}
                        </div>
                      ) : (
                        <div className="">
                          {<GetDay code={new Date(li.orderdate).getDay()} />}{" "}
                          {new Date(li.orderdate).getDate()}/
                          {new Date(li.orderdate).getMonth() + 1}/
                          {new Date(li.orderdate).getFullYear()}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div>
                        {<GetDay code={new Date(li.orderdate).getDay()} />}{" "}
                        {new Date(li.orderdate).getDate()}/
                        {new Date(li.orderdate).getMonth() + 1}/
                        {new Date(li.orderdate).getFullYear()}
                      </div>
                    </>
                  )}
                </div>
                <div className="occolm">{li.orderref}</div>
                <div className="occols">{li.settingname}</div>
                <div className="occols">
                  <>
                    {" "}
                    <div>
                      <GetDay code={new Date(li.bookdate).getDay()} />
                    </div>
                    <div>
                      {new Date(li.bookdate).getDate()}/
                      {new Date(li.bookdate).getMonth() + 1}/
                      {new Date(li.bookdate).getFullYear()}
                    </div>
                    <div>{li.booktime.slice(0, 5)}</div>
                  </>
                </div>
                <div className="occols">
                  <div>{li.name}</div>
                  <div>
                    {li.options.length > 0 && (
                      <div>
                        {li.options.map((option, optionindex) => {
                          return (
                            <small key={optionindex}>
                              {option.description}
                            </small>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="occols">
                  <small>{li.sku}</small>
                </div>
                <div className="occols">{li.quantity}</div>
                <div className="occols">
                  {li.varianttype === "cuttoweight" ? (
                    <>{li.weight}g</>
                  ) : (
                    <>NA</>
                  )}
                </div>

                <div className="occols">
                  {li.ordered === false ? (
                    <>
                      <button
                        className="border border-0 bg-transparent"
                        onClick={() => orderUpdateItem(li)}
                      >
                        <i className="bi bi-square"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="border border-0 bg-transparent"
                        onClick={() => orderUpdateItem(li)}
                      >
                        <i className="bi bi-check-square"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PreOrderListing;
