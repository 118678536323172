import { createContext, useState, useEffect, useContext } from "react";

import ControlContext from "./control-context";
import AuthContext from "./auth-context";

const ShopContext = createContext({
  category: null,
  setcategory: () => {},

  subcategory: null,
  setsubcategory: () => {},

  categories: null,
  getcategorieslist: () => {},
  createcategory: () => {},
  updatecategory: () => {},
  deletecategory: () => {},

  subcategories: null,
  getsubcategorieslist: () => {},
  createsubcategory: () => {},
  updatesubcategory: () => {},
  deletesubcategory: () => {},

  products: null,
  productdetail: null,
  setproductdetail: () => {},
  getproductlist: () => {},
  getproductdetail: () => {},

  productsettings: null,
  getproductsettings: () => {},

  productoptions: null,
  getproductoptions: () => {},

  produktlistchange: null,

  shopoptions: [],
  getshopoptions: () => {},
  updateshopoptions: () => {},

  createproductwimage: () => {},
  updateproductwimage: () => {},
  deleteproductwimage: () => {},

  getallposinfo: () => {},

  availas: null,
  locs: null,
  slots: null,

  createavailas: () => {},
  updateavailas: () => {},
  deleteavailas: () => {},

  createlocs: () => {},
  updatelocs: () => {},
  deletelocs: () => {},

  createslots: () => {},
  updateslots: () => {},
  deleteslots: () => {},

  reload: null,
  setreload: () => {},
});

export function ShopProvider(props) {
  const controlObject = useContext(ControlContext);
  const authObject = useContext(AuthContext);
  let [category, setCategory] = useState(null);
  let [subCategory, setSubCategory] = useState(null);
  let [products, setProducts] = useState();
  let [productDetail, setProductDetail] = useState();
  let [productSettings, setProductSettings] = useState();
  let [productOptions, setProductOptions] = useState();
  let [shopOptions, setShopOptions] = useState([]);
  let [categories, setCategories] = useState();
  let [subCategories, setSubCategories] = useState();
  let [availas, setAvailas] = useState();
  let [locs, setLocs] = useState();
  let [slots, setSlots] = useState();
  let [reload, setReload] = useState();
  let [reloadProduct, setReloadProduct] = useState();
  let [reloadPOS, setReloadPOS] = useState();
  let [produktListChange, setProductListChange] = useState();

  useEffect(() => {
    console.log("useEffect 1 of shop-context");
    if (authObject.user && localStorage.getItem("access")) {
      // getProductList();
      getCategoriesList();
      getAllPosInfo();
      getShopOptions();
      setCategory(null);
      setSubCategory(null);
      getProductSettings();
    }
  }, [controlObject.webshop, reload]);

  useEffect(() => {
    console.log("useEffect 2 of shop-context");
    if (authObject.user) {
      if (category) {
        getProductList(controlObject.webshop.id, category.id);
        getProductOptions(controlObject.webshop.id, category.id);
      }
      getCategoriesList();
      getSubCategoriesList();
    }
  }, [reloadProduct]);

  useEffect(() => {
    if (category !== null) {
      getProductList(controlObject.webshop.id, category.id);
    }
  }, [category, reload]);

  useEffect(() => {
    if (authObject.user && localStorage.getItem("access")) {
      getAllPosInfo();
    }
  }, [reloadPOS]);

  let getProductOptions = async (webshopid, catid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/options/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          catid: catid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      console.log("loaded productoptions in shop-context:", data);
      setProductOptions(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getProductList = async (webshopid, catid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          catid: catid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setProducts(data);
      console.log("productlist:", data);
      setProductListChange(!produktListChange);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getProductSettings = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/settings/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setProductSettings(data);
      console.log("prodsetlist loaded");
    } else {
      alert("something went wrong checking your shopoptions");
    }
  };

  let getProductDetail = async (product) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/detail/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          product: product,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setProductDetail(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let createProductwImage = async (product) => {
    console.log("creating product");
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/", {
      method: "POST",
      headers: {
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: product,
    });
    if (resp.status === 200) {
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateProductwImage = async (product) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/", {
      method: "PATCH",
      headers: {
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: product,
    });
    if (resp.status === 200) {
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let deleteProductwImage = async (product) => {
    console.log("deleting product");
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/", {
      method: "DELETE",
      headers: {
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: product,
    });
    if (resp.status === 200) {
      console.log("Product with image has been deleted");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let getCategoriesList = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/categories/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setCategories(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getSubCategoriesList = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/subcats/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setSubCategories(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let createCategory = async (category) => {
    console.log("category :", category);
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/categories/create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          category: category,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("category created");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let createSubCategory = async (subcategory, category) => {
    console.log("category :", category);
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/subcats/create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          categoryid: category.id,
          subcategory: subcategory,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("subcategory created");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateCategory = async (category) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/categories/update/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          category: category,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("category updated");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateSubCategory = async (subcategory) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/subcats/update/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          subcategory: subcategory,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("subcategory updated");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let deleteCategory = async (category) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/categories/delete/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          category: category,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("category deleted");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let deleteSubCategory = async (subcategory) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/subcats/delete/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          subcategory: subcategory,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("subcategory deleted");
      setReloadProduct(!reloadProduct);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getShopOptions = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/webshop/getoptions/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setShopOptions(data);
      shopOptions = data;
    } else {
      console.log("no shopoptions could be loaded");
    }
  };

  let updateShopOptions = async (options) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/webshop/updateoptions/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(options),
      }
    );
    if (resp.status === 200) {
      getShopOptions();
    } else {
      alert("something went wrong in updating shopoptions");
    }
  };

  // webshopid: controlObject.webshop.id,
  let getAvailas = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/availas/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setAvailas(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getLocs = async () => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/locs/list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: controlObject.webshop.id,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setLocs(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getSlots = async () => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/slots/list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: controlObject.webshop.id,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setSlots(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getAllPosInfo = () => {
    getAvailas();
    getLocs();
    getSlots();
  };

  let createAvailas = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/availas/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Availability has been created");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateAvailas = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/availas/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Availability has been updated");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let deleteAvailas = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/availas/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Availability has been deleted");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let createLocs = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/locs/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Loc has been created");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateLocs = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/locs/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Loc has been updated");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let deleteLocs = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/locs/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Loc has been deleted");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let createSlots = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/slots/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Slot has been created");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateSlots = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/slots/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Slot has been updated");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let deleteSlots = async (item) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/slots/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify(item),
    });
    if (resp.status === 200) {
      console.log("Slot has been deleted");
      setReloadPOS(!reloadPOS);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  let context = {
    category: category,
    setcategory: setCategory,

    subcategory: subCategory,
    setsubcategory: setSubCategory,

    categories: categories,
    getcategorieslist: getCategoriesList,
    createcategory: createCategory,
    updatecategory: updateCategory,
    deletecategory: deleteCategory,

    subcategories: subCategories,
    getsubcategorieslist: getSubCategoriesList,
    createsubcategory: createSubCategory,
    updatesubcategory: updateSubCategory,
    deletesubcategory: deleteSubCategory,

    products: products,
    productdetail: productDetail,
    setproductdetail: setProductDetail,
    getproductlist: getProductList,
    getproductdetail: getProductDetail,

    productlistchange: produktListChange,

    productsettings: productSettings,
    getproductsettings: getProductSettings,

    productoptions: productOptions,
    getproductoptions: getProductOptions,

    shopoptions: shopOptions,
    getshopoptions: getShopOptions,
    updateshopoptions: updateShopOptions,

    createproductwimage: createProductwImage,
    updateproductwimage: updateProductwImage,
    deleteproductwimage: deleteProductwImage,

    getallposinfo: getAllPosInfo,

    availas: availas,
    locs: locs,
    slots: slots,

    createavailas: createAvailas,
    updateavailas: updateAvailas,
    deleteavailas: deleteAvailas,

    createlocs: createLocs,
    updatelocs: updateLocs,
    deletelocs: deleteLocs,

    createslots: createSlots,
    updateslots: updateSlots,
    deleteslots: deleteSlots,

    reload: reload,
    setreload: setReload,
  };

  return (
    <ShopContext.Provider value={context}>
      {props.children}
    </ShopContext.Provider>
  );
}

export default ShopContext;
