import React, { useState, useContext, useEffect } from "react";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";
import AuthContext from "../Store/auth-context";
import SOCheckbox from "../Components/SOCheckbox";

const ShopOptions = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const authObject = useContext(AuthContext);

  const [options, setOptions] = useState(shopObject.shopoptions);
  const [reset, setReset] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    shopObject.getshopoptions();
  }, [reset, controlObject.webshop]);

  useEffect(() => {
    setOptions(shopObject.shopoptions);
  }, [shopObject.shopoptions]);

  // let updateShopOptions = async (item) => {
  //   item.layout = JSON.stringify(item.layout);
  //   let resp = await fetch(
  //     process.env.REACT_APP_API_URL + "capi/webshop/updateoptions/",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "JWT " + String(localStorage.getItem("access")),
  //       },
  //       body: JSON.stringify(item),
  //     }
  //   );
  //   if (resp.status === 200) {
  //     setOptions();
  //     setReset(!reset);
  //     setUpdating(false);
  //   } else {
  //     alert("something went wrong in updating shopoptions");
  //   }
  // };

  function handler(name, option) {
    let value = !option;
    setUpdating(true);
    setOptions({ ...options, [name]: value });
  }

  function normalhandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setUpdating(true);
    setOptions({ ...options, [name]: value });
  }

  function update() {
    // checking for shipments

    if (
      options.en_postnl &&
      options.en_postnl_sandbox &&
      authObject.organisation.api_pnl_sandbox === ""
    ) {
      options.en_postnl = false;
      options.en_postnl_sandbox = false;
    }

    if (
      options.en_postnl &&
      authObject.organisation.api_pnl_real === "" &&
      !options.en_postnl_sandbox
    ) {
      options.en_postnl = false;
    }

    if (options.en_shipping && options.en_postnl === false) {
      options.en_shipping = false;
    }

    if (!options.en_shipping) {
      options.en_postnl = false;
      options.en_postnl_sandbox = false;
    }
    // checking for payments
    if (options.en_sandboxpayment && !options.en_onlinepayment) {
      options.en_onlinepayment = false;
    }
    if (
      options.en_onlinepayment &&
      options.en_sandboxpayment &&
      (authObject.organisation.sandbox_saferpayterminalid === "" ||
        authObject.organisation.sandbox_saferpayheader === "" ||
        authObject.organisation.sandbox_saferpaycustid === "")
    ) {
      options.en_sandboxpayment = false;
    }
    if (
      options.en_onlinepayment &&
      !options.en_sandboxpayment &&
      (authObject.organisation.saferpayterminalid === "" ||
        authObject.organisation.saferpayheader === "" ||
        authObject.organisation.saferpaycustid === "")
    ) {
      options.en_onlinepayment = false;
    }
    shopObject.updateshopoptions(options);
    setUpdating(false);
  }

  return (
    <>
      {options && (
        <>
          <div>
            <div className="row">
              <div className="scsubtitle col-3">Shop Options</div>
              {updating && (
                <>
                  <div className="scline col-3">
                    <button
                      className="btn btn-success btn-sm scaddbutton"
                      onClick={() => update()}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-secondary btn-sm scaddbutton"
                      onClick={() => {
                        setReset(!reset);
                        setUpdating(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="fw-bold">Pickup/Shipping</div>
            <SOCheckbox
              title="Enable shipping"
              name="en_shipping"
              value={options.en_shipping}
              handler={handler}
            />
            {options.en_shipping && <></>}
            <div className="ms-3">
              Make sure all API-details are filled out under organisation -
              details
              <SOCheckbox
                title="Enable PostNL"
                name="en_postnl"
                value={options.en_postnl}
                handler={handler}
              />
              <SOCheckbox
                title="SandBox only"
                name="en_postnl_sandbox"
                value={options.en_postnl_sandbox}
                handler={handler}
              />
            </div>

            <SOCheckbox
              title="Enable Pickup at Locations Option"
              name="en_pickup"
              value={options.en_pickup}
              handler={handler}
            />
            <div className="fw-bold mt-2">Payments</div>
            <SOCheckbox
              title="Enable Worldline E-commerce"
              name="en_onlinepayment"
              value={options.en_onlinepayment}
              handler={handler}
            />
            {options.en_onlinepayment && (
              <>
                <div>
                  <div className="ms-3">
                    Make sure all API's are filled in under user - organisation
                    details
                    <SOCheckbox
                      title="Testpayments only"
                      name="en_sandboxpayment"
                      value={options.en_sandboxpayment}
                      handler={handler}
                    />
                  </div>
                  <div className="ms-3">
                    <label>
                      number of successfull online payments before pickup
                      payment allowed
                    </label>
                    <input
                      className=""
                      placeholder="number online orders"
                      type="number"
                      size="1"
                      id="minonlineorders"
                      name="minonlineorders"
                      default="1"
                      value={options.minonlineorders}
                      onChange={normalhandler}
                      min="0"
                    />
                  </div>
                </div>
              </>
            )}

            <SOCheckbox
              title="Enable payment by bank transfer"
              name="en_transferpayment"
              value={options.en_transferpayment}
              handler={handler}
            />
            <SOCheckbox
              title="Enable payment on pickup"
              name="en_pickuppayment"
              value={options.en_pickuppayment}
              handler={handler}
            />

            <div className="fw-bold mt-2">Short term preparations</div>
            <SOCheckbox
              title="Make use of Cooking Timer"
              name="usecooktimer"
              value={options.usecooktimer}
              handler={handler}
            />

            {options.usecooktimer && (
              <>
                <label>please enter a value between 0 and 20 minutes</label>
                <input
                  className="ms-3"
                  placeholder="minutes"
                  type="number"
                  size="2"
                  id="cooktimer"
                  name="cooktimer"
                  default="10"
                  value={options.cooktimer}
                  onChange={normalhandler}
                  min="0"
                  max="20"
                ></input>
              </>
            )}
            <SOCheckbox
              title="Allow same day ordering"
              name="allowsameday"
              value={options.allowsameday}
              handler={handler}
            />

            {options.allowsameday && (
              <>
                <label>please enter a value in minutes before timeslot</label>
                <input
                  className="ms-3"
                  placeholder="minutes"
                  type="number"
                  size="2"
                  id="samedayspan"
                  name="samedayspan"
                  default="1"
                  value={options.samedayspan}
                  onChange={normalhandler}
                  min="0"
                ></input>
              </>
            )}

            <div className="scsubtitle">Mail Options</div>
            <div>
              <strong>Send email from</strong>
            </div>
            <div>
              <small>
                Please note : only works when validated with Ubicopia®
              </small>
            </div>
            <div className="mb-2">
              <label>Email adres</label>
              <input
                className="ms-3"
                placeholder="email adres"
                type="email"
                id="mailfrom"
                name="mailfrom"
                default="mailer@ubicopia.com"
                value={options.mailfrom}
                onChange={normalhandler}
              />
            </div>
            <div>
              <strong>During shopping</strong>
            </div>
            <SOCheckbox
              title="Mail Orderconfirmation"
              name="mail_confirmorder"
              value={options.mail_confirmorder}
              handler={handler}
            />
            <SOCheckbox
              title="Attachment pdf of order included"
              name="mail_confirmattach"
              value={options.mail_confirmattach}
              handler={handler}
            />
            <SOCheckbox
              title="Mail confirmation of payment type selection"
              name="mail_paymentselection"
              value={options.mail_paymentselection}
              handler={handler}
            />
            <SOCheckbox
              title="Confirmation mail of successfull online payment"
              name="mail_onlinepaymentsuccess"
              value={options.mail_onlinepaymentsuccess}
              handler={handler}
            />
            <div>
              <strong>During processing</strong>
            </div>

            <SOCheckbox
              title="Confirmation of receipt of transfer payment"
              name="mail_transferpaymentreceived"
              value={options.mail_transferpaymentreceived}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Start of order processing"
              name="mail_processing"
              value={options.mail_processing}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Order ready for pickup"
              name="mail_readyforpickup"
              value={options.mail_readyforpickup}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Order ready for shipment"
              name="mail_readyforshipment"
              value={options.mail_readyforshipment}
              handler={handler}
            />
            <SOCheckbox
              title="Mail with invoice"
              name="mail_invoice"
              value={options.mail_invoice}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Order Shipped"
              name="mail_ordershipped"
              value={options.mail_ordershipped}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Order Picked Up"
              name="mail_orderpickedup"
              value={options.mail_orderpickedup}
              handler={handler}
            />
            <SOCheckbox
              title="Mail Order arrived at destination"
              name="mail_orderarrived"
              value={options.mail_orderarrived}
              handler={handler}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ShopOptions;
