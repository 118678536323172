import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";
import OrderList from "../Components/OrderList";
import PreOrderList from "../Components/PreOrderList";
import POSPrepList from "../Components/POSPrepList";
import POSOrderItemList from "../Components/POSOrderItemList";
import PrintButton from "../Components/PrintButton";

const OrderController = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const [reset, setReset] = useState();
  const [bfilter, setBFilter] = useState("unshipped");
  const [sortASC, setSortASC] = useState(true);
  const [listType, setListType] = useState("orderlist");

  useEffect(() => {
    if (bfilter === "unfinishedproducts") {
      controlObject.getunfinisheditemscompressed(controlObject.webshop.id);
      console.log("webshop id :", controlObject.webshop.id);
      shopObject.getallposinfo();
      setListType("productlist");
    }
    if (bfilter === "unpaid") {
      setListType("orderlist");
      controlObject.getunpaidorderlist(controlObject.webshop.id, sortASC);
    }
    if (bfilter === "unfinished") {
      setListType("orderlist");
      controlObject.getunfinishedorderlist(controlObject.webshop.id, true);
    }
    if (bfilter === "toorderproducts") {
      setListType("preorderlist");
      controlObject.getunfinishedorderlist(controlObject.webshop.id, true);
    }
    if (bfilter === "pickuporder") {
      setListType("orderlist");
      controlObject.getopenposorderlist(controlObject.webshop.id, sortASC);
    }
    if (bfilter === "shiporder") {
      setListType("orderlist");
      controlObject.getopenshiporderlist(controlObject.webshop.id, sortASC);
    }
    if (bfilter === "unshipped") {
      setListType("orderlist");
      controlObject.getopenorderlist(controlObject.webshop.id, sortASC);
    }
    if (bfilter === "shipped") {
      setListType("orderlist");
      controlObject.getshippedorderlist(controlObject.webshop.id, sortASC);
    }
    if (bfilter === "all") {
      setListType("orderlist");
      controlObject.getallorderlist(controlObject.webshop.id, sortASC);
    }
  }, [
    controlObject.webshop,
    controlObject.orderupdate,
    bfilter,
    reset,
    sortASC,
  ]);

  return (
    <div className="ocpage">
      <Helmet>
        <title>Ubicopia®-Order Controller</title>
        <meta name="description" content="Control your orders" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container p-0 mb-3">
        <div className="row ocheader">
          <div className="col-3">
            <div className="row octitle">OrderController</div>
          </div>

          <div className="col-2">
            <div className="row">
              <button
                className="btn btn-primary btn-sm row w-100"
                onClick={(e) => {
                  setBFilter("unshipped");
                }}
              >
                Order management
              </button>
            </div>
            <div className="row mt-2">
              <button
                className="btn btn-primary btn-sm row w-100"
                onClick={(e) => {
                  setBFilter("unfinishedproducts");
                }}
              >
                Order Processing
              </button>
            </div>
            <div className="row mt-2">
              <button
                className="btn btn-primary btn-sm row w-100"
                onClick={(e) => {
                  setBFilter("toorderproducts");
                }}
              >
                Supply orders
              </button>
            </div>
          </div>
          <div className="col-7">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("unpaid");
                  }}
                >
                  Unpaid orders
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("pickuporder");
                  }}
                >
                  Unfinished for Pickup
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("shipped");
                  }}
                >
                  Shipped orders
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("unfinished");
                  }}
                >
                  All unfinished orders
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("shiporder");
                  }}
                >
                  Unfinished for mail
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-secondary btn-sm w-100"
                  onClick={(e) => {
                    setBFilter("all");
                  }}
                >
                  All orders
                </button>
                <button
                  className="btn btn-success btn-sm w-100 mt-2"
                  onClick={(e) => {
                    setReset(!reset);
                  }}
                >
                  Reload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {controlObject.webshop.ref === "" && (
        <>You need to select a webshop from the list</>
      )}
      {listType === "productlist" && (
        <>
          <div className="d-flex justify-content-end me-5">
            <PrintButton />
          </div>
          {controlObject.workitemlistcompressed && (
            <POSPrepList
              itemlist={controlObject.workitemlistcompressed}
              controlObject={controlObject}
            />
          )}
          {controlObject.workitemlist &&
            controlObject.workorderlist &&
            controlObject.workbookinglist && (
              <POSOrderItemList itemlist={controlObject.workitemlist} />
            )}
        </>
      )}
      {listType === "preorderlist" && (
        <>
          <div className="d-flex justify-content-end me-5">
            <PrintButton />
          </div>
          <PreOrderList
            title="Orders to pre-order"
            list={controlObject.workorderlist.filter(
              (o) =>
                (o.shipmethod === "M" || o.shipmethod === "P") &&
                o.readyforship === null &&
                (o.paymethod === "P" || o.paid != null) &&
                o.onhold === false
            )}
            reset={reset}
            setReset={setReset}
            webshop={controlObject.webshop.id}
            settings={shopObject.productsettings}
          />
        </>
      )}
      {listType === "orderlist" && (
        <>
          <div className="d-flex justify-content-end me-5">
            <PrintButton />
          </div>
          <OrderList
            title="Orders Failed During online payment"
            list={controlObject.workorderlist.filter(
              (o) => o.paymethod === "O" && o.paid === null
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders Failed During processing (no paymethod)"
            list={controlObject.workorderlist.filter(
              (o) => o.paymethod === null
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders Waiting BankTransfer"
            list={controlObject.workorderlist
              .filter((o) => o.paymethod === "T" && o.paid === null)
              .filter((o) => o.onhold === false)}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders on hold"
            list={controlObject.workorderlist.filter((o) => o.onhold === true)}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders to Prepare for Shipping"
            list={controlObject.workorderlist.filter(
              (o) =>
                o.shipmethod === "M" &&
                o.readyforship === null &&
                o.paid != null &&
                o.onhold === false
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders to Prepare for Pickup"
            list={controlObject.workorderlist.filter(
              (o) =>
                o.shipmethod === "P" &&
                o.readyforship === null &&
                (o.paymethod === "P" || o.paid != null) &&
                o.onhold === false
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders Ready for Shipping"
            list={controlObject.workorderlist.filter(
              (o) =>
                o.readyforship != null &&
                o.shipmethod === "M" &&
                o.shipped === null &&
                o.onhold === false
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
          <OrderList
            title="Orders ready for pickup"
            list={controlObject.workorderlist.filter(
              (o) =>
                o.readyforship != null &&
                o.shipmethod === "P" &&
                o.shipped === null &&
                o.onhold === false
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />

          <OrderList
            title="Orders Shipped"
            list={controlObject.workorderlist.filter(
              (o) => o.shipped != null && o.onhold === false
            )}
            sortASC={sortASC}
            setSortASC={setSortASC}
            reset={reset}
            setReset={setReset}
          />
        </>
      )}
    </div>
  );
};

export default OrderController;
