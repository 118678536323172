import React, { useState, Fragment, useEffect } from "react";
import OrderLine from "./OrderLine";
import getNormDate from "../Functions/getNormDate";
import readDay from "../Functions/readDay";
import GetDay from "./GetDay";
import GetTime from "./GetTime";
import getPreviousDay from "../Functions/getPreviousDay";
import getPreviousWeekDay from "../Functions/getPreviousWeekDay";
import getPrevious2WeekDay from "../Functions/getPrevious2WeekDay";

import PreOrderListing from "./PreOrderListing";

const PreOrderList = ({
  title,
  list,
  sortASC,
  setSortASC,
  reset,
  setReset,
  settings,
  webshop,
}) => {
  var [showList, setShowList] = useState(list);
  var [leadItems, setLeadItems] = useState([]);
  const [preOrderItems, setPreOrderItems] = useState();
  const [optionList, setOptionList] = useState();

  let getUnfinishedItemsOptions = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items-options/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshop,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOptionList(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getProductDetailSettings = async (webshop, orderList) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/detailsettings/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshop,
          orderList: orderList,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      data = data.filter((d) => d.settings.length > 0);
      leadItems = data;
      setLeadItems(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  useEffect(() => {
    var itemlist = [];
    list.map((order) => {
      var item = { id: order.id, items: order.items };
      itemlist.push(item);
    });
    getProductDetailSettings(webshop, itemlist);
    getUnfinishedItemsOptions();
    setShowList(list);
    showList = list;
  }, []);

  useEffect(() => {
    var itemlist = [];
    list.map((order) => {
      var item = { id: order.id, items: order.items };
      itemlist.push(item);
    });
    getProductDetailSettings(webshop, itemlist);
    getUnfinishedItemsOptions();
    setShowList(list);
    showList = list;
  }, [reset]);

  // looping through the items with productsettings and adding orderdate w supplier to leaditems
  useEffect(() => {
    leadItems.map((item) => {
      item.settings.map((setting) => {
        if (!item.orderdate) {
          item.orderdate = whatisorderdate(item, setting);
          item.settingname = setting.name;
          return;
        }
        if (whatisorderdate(item, setting) > item.orderdate) {
          item.orderdate = whatisorderdate(item, setting);
          item.settingname = setting.name;
        }
      });
    });
    // Sorting leaditems according to "to order date"
    leadItems.sort(
      (a, b) =>
        new Date(a.orderdate).getTime() - new Date(b.orderdate).getTime()
    );
    setPreOrderItems(leadItems);
  }, [leadItems]);

  // MAIN PART - calculating the supplier order date
  function whatisorderdate(item, setting) {
    // in the case of a leadtime --> OK
    if (setting.leadtime !== null) {
      var date = new Date(item.bookdate);
      date.setDate(date.getDate() - setting.leadtime);
      return new Date(date).setHours(0, 0, 0, 0);
    }

    // in the case of fixed order-delivery --> NOK
    if (setting.leadtime === null) {
      var bookdate = new Date(item.bookdate);
      var deldate = new Date(setting.delivery);
      var orderdate = new Date(setting.deadline);

      var bookday = bookdate.getDay();
      var booktime =
        parseInt(item.booktime.slice(0, 2)) * 60 +
        parseInt(item.booktime.slice(3, 5));

      var delday = deldate.getDay();
      var deltime = deldate.getMinutes() + 60 * deldate.getHours();

      var orderday = orderdate.getDay();
      var ordertime = orderdate.getMinutes() + 60 * orderdate.getHours();

      if (delday > orderday) {
        if (bookday >= delday) {
          if (booktime >= deltime) {
            return new Date(getPreviousDay(orderday, bookdate)).setHours(
              0,
              0,
              0,
              0
            );
          }
          if (booktime < deltime) {
            return new Date(getPreviousWeekDay(orderday, bookdate)).setHours(
              0,
              0,
              0,
              0
            );
          }
        }
        if (bookday < delday) {
          return new Date(getPreviousWeekDay(orderday, bookdate)).setHours(
            0,
            0,
            0,
            0
          );
        }
        return new Date(getPreviousDay(orderday, bookdate)).setHours(
          0,
          0,
          0,
          0
        );
      }

      if (delday < orderday) {
        if (bookday >= delday) {
          if (booktime >= deltime) {
            return new Date(getPreviousWeekDay(orderday, bookdate)).setHours(
              0,
              0,
              0,
              0
            );
          }
          if (booktime < deltime) {
            return new Date(getPrevious2WeekDay(orderday, bookdate)).setHours(
              0,
              0,
              0,
              0
            );
          }
        }
        if (bookday < delday) {
          return new Date(getPrevious2WeekDay(orderday, bookdate)).setHours(
            0,
            0,
            0,
            0
          );
        }
        return new Date(getPreviousWeekDay(orderday, bookdate)).setHours(
          0,
          0,
          0,
          0
        );
      }

      return "day";
    }
  }

  let orderCartItemOrdered = async (webshop, itemid, value) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/cartitem/ordered/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshop,
          itemid: itemid,
          value: value,
        }),
      }
    );
    if (resp.status === 200) {
      setReset(!reset);
    } else {
      alert("something went wrong checking your details");
    }
  };

  function orderUpdateItem(item) {
    if (item.ordered === false) {
      orderCartItemOrdered(webshop, item.itemid, true);
    }
    if (item.ordered === true) {
      orderCartItemOrdered(webshop, item.itemid, false);
    }
  }

  return (
    <>
      {/* ------------------ Orderlist by Order ------------------ */}
      {preOrderItems && preOrderItems.length > 0 && (
        <div>
          <div className="ocsubtitle">{title}</div>
          <div className="octitlerow">
            <div className="occolm">Date to order</div>
            <div className="occolm">OrderRef</div>
            <div className="occols">Setting</div>
            <div className="occols">Booking</div>
            <div className="occols">Item</div>
            <div className="occols">SKU</div>
            <div className="occols">Qty</div>
            <div className="occols">Weight</div>
            <div className="occols">Besteld ?</div>
          </div>
          <div>Items with leadtimes to be ordered :</div>
          <PreOrderListing
            itemList={preOrderItems.filter((li) => li.ordered === false)}
            orderUpdateItem={orderUpdateItem}
            accentuate={true}
          />
          <div>Items with leadtimes already ordered :</div>
          <PreOrderListing
            itemList={preOrderItems.filter((li) => li.ordered === true)}
            orderUpdateItem={orderUpdateItem}
            accentuate={false}
          />
          {/* {preOrderItems.filter((li) => li.ordered === false) &&
            preOrderItems.length > 0 && (
              <div>
                {preOrderItems.map((li, li_index) => {
                  return (
                    <div index={li_index} className="ocdatarow">
                      <div className="occols">
                        <div>
                          {<GetDay code={new Date(li.orderdate).getDay()} />}{" "}
                          {new Date(li.orderdate).getDate()}/
                          {new Date(li.orderdate).getMonth() + 1}/
                          {new Date(li.orderdate).getFullYear()}
                        </div>
                      </div>
                      <div className="occols">{li.orderref}</div>
                      <div className="occols">{li.settingname}</div>
                      <div className="occols">
                        <>
                          {" "}
                          <div>
                            <GetDay code={new Date(li.bookdate).getDay()} />
                          </div>
                          <div>
                            {new Date(li.bookdate).getDate()}/
                            {new Date(li.bookdate).getMonth() + 1}/
                            {new Date(li.bookdate).getFullYear()}
                          </div>
                          <div>{li.booktime.slice(0, 5)}</div>
                        </>
                      </div>
                      <div className="occols">
                        <div>{li.name}</div>
                        <div>
                          {li.options.length > 0 && (
                            <div>
                              {li.options.map((option, optionindex) => {
                                return (
                                  <small index={optionindex}>
                                    {option.description}
                                  </small>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="occols">{li.sku}</div>
                      <div className="occols">{li.quantity}</div>
                      <div className="occols">
                        {li.varianttype === "cuttoweight" ? (
                          <>{li.weight}g</>
                        ) : (
                          <>NA</>
                        )}
                      </div>

                      <div className="occols">
                        {li.ordered === false ? (
                          <>
                            <button
                              className="border border-0 bg-transparent"
                              onClick={() => orderUpdateItem(li)}
                            >
                              <i className="bi bi-square"></i>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="border border-0 bg-transparent"
                              onClick={() => orderUpdateItem(li)}
                            >
                              <i className="bi bi-check-square"></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )} */}
        </div>
      )}
    </>
  );
};

export default PreOrderList;
