import React, { useState, useEffect, Fragment } from "react";
import AddProductOption from "./AddProductOption";
import EditProductOption from "./EditProductOption";
import DeleteProductOption from "./DeleteProductOption";

const ProductOption = ({
  productid,
  webshopid,
  productOptions,
  setWorkingOptions,
}) => {
  const [options, setOptions] = useState(productOptions);
  const [addToggler, setAddToggler] = useState(false);
  const [editToggler, setEditToggler] = useState(null);
  const [delToggler, setDelToggler] = useState(null);
  const [loadOptions, setLoadOptions] = useState(false);

  useEffect(() => {
    readProductOptions(webshopid, productid);
  }, [loadOptions]);

  let readProductOptions = async (webshopid, prodid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/options/product/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          prodid: prodid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      data = data.sort((a, b) => a.description.localeCompare(b.description));
      setOptions(data);
    } else {
      alert("something went wrong reading product data in Productoptions");
    }
  };

  const addHandler = () => {
    setAddToggler(true);
    setWorkingOptions(true);
  };

  return (
    <div>
      <div className="row mt-3 mb-3">
        <div className="col-8">
          <strong>Options</strong>
        </div>
        {!addToggler && (
          <>
            <button
              className="btn btn-primary btn-sm col-3"
              onClick={() => addHandler()}
            >
              Add
            </button>
          </>
        )}

        {addToggler && (
          <AddProductOption
            productid={productid}
            webshopid={webshopid}
            setToggler={setAddToggler}
            loadOptions={loadOptions}
            setLoadOptions={setLoadOptions}
            setWorkingOptions={setWorkingOptions}
          />
        )}
        {options &&
          editToggler === null &&
          delToggler === null &&
          addToggler === false && (
            <div className="row mt-2">
              {options.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <>
                      <div className="row mt-2">
                        <div className="col-9">
                          {option.description} -{" "}
                          {(option.price * (1 + option.vat)).toFixed(2)}
                        </div>
                        <button
                          className="border border-0 col-1 bg-transparent"
                          onClick={(e) => {
                            setEditToggler(option);
                            setWorkingOptions(true);
                          }}
                        >
                          <i className="bi bi-pen"></i>
                        </button>
                        <button
                          className="border border-0 col-1 bg-transparent"
                          onClick={(e) => {
                            setDelToggler(option);
                            setWorkingOptions(true);
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </>
                  </Fragment>
                );
              })}
            </div>
          )}
        {editToggler !== null && (
          <>
            <EditProductOption
              option={editToggler}
              setToggler={setEditToggler}
              loadOptions={loadOptions}
              setLoadOptions={setLoadOptions}
              setWorkingOptions={setWorkingOptions}
            />
          </>
        )}
        {delToggler !== null && (
          <>
            <DeleteProductOption
              option={delToggler}
              setToggler={setDelToggler}
              loadOptions={loadOptions}
              setLoadOptions={setLoadOptions}
              setWorkingOptions={setWorkingOptions}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductOption;
