function getPreviousWeekDay(orderday, bookdate) {
  // console.log("inputs : orderday:", orderday, " bookdate:", bookdate);
  const bookday = new Date(bookdate).getDay();
  const difference = bookday - orderday;
  const orderdate = new Date();
  // console.log(
  //   "bookday:",
  //   bookday,
  //   " - orderday",
  //   orderday,
  //   " - diff:",
  //   difference
  // );
  orderdate.setDate(new Date(bookdate).getDate() - difference - 7);
  // console.log("calculated orderdate :", orderdate);
  return orderdate;
}

export default getPreviousWeekDay;
