import React, { useContext, useState, Fragment, useEffect } from "react";

const POSPrepList = ({ itemlist, controlObject }) => {
  const [optionList, setOptionList] = useState();

  let getUnfinishedItemsOptions = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items-options/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOptionList(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  useEffect(() => {
    getUnfinishedItemsOptions();
    controlObject.getunfinisheditemscompressed(controlObject.webshop.id);
  }, [controlObject.orderupdate]);

  useEffect(() => {
    getUnfinishedItemsOptions();
    controlObject.getunfinisheditemscompressed(controlObject.webshop.id);
  }, []);

  return (
    <>
      <div className="ocsubtitle">
        <button
          className="ocsubtitle btn btn-success"
          onClick={() =>
            controlObject.setorderupdate(!controlObject.orderupdate)
          }
        >
          Prep List
        </button>
      </div>

      {/* ------------------ Orderlist by Product ------------------ */}
      {itemlist.length > 0 && (
        <div className="w-75 mx-auto ocpreplist pagebreak">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>SKU </th>
                <th>Quantity</th>
                <th>Weight</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {itemlist &&
                optionList &&
                itemlist.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.sku}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {item.varianttype === "cuttoweight" ? (
                            <>{item.weight}g</>
                          ) : (
                            <>NA</>
                          )}
                        </td>
                        <td colSpan="1">
                          <table className="table table-sm">
                            {item.options.length > 0 && (
                              <tbody>
                                {item.options.map((option, index2) => {
                                  return (
                                    <tr key={index2}>
                                      {Array.isArray(option) && (
                                        <>
                                          {option.map((o, index3) => {
                                            return (
                                              <Fragment key={index3}>
                                                {o.map((opti, index4) => {
                                                  return (
                                                    <Fragment key={index4}>
                                                      {optionList.filter(
                                                        (op) => op.id === opti
                                                      )[0] && (
                                                        <>
                                                          {index4 > 0 && (
                                                            <>{", "}</>
                                                          )}
                                                          {
                                                            optionList.filter(
                                                              (op) =>
                                                                op.id === opti
                                                            )[0].description
                                                          }
                                                        </>
                                                      )}
                                                    </Fragment>
                                                  );
                                                })}
                                              </Fragment>
                                            );
                                          })}
                                        </>
                                      )}
                                      {!Array.isArray(option) && (
                                        <>
                                          {optionList &&
                                            optionList.filter(
                                              (op) => op.id === option
                                            )[0] && (
                                              <>
                                                {
                                                  optionList.filter(
                                                    (op) => op.id === option
                                                  )[0].description
                                                }
                                              </>
                                            )}
                                        </>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default POSPrepList;
