import React, { useState, useContext, Fragment } from "react";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";
import NormDate from "./NormDate";
import OrderLine from "./OrderLine";
import ItemLine from "./ItemLine";
import Bookingline from "./Bookingline";
import CookCounter from "./CookCounter";
import getMills from "../Functions/getMills";
import { useEffect } from "react";

const POSOrderItemList = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const [orderList, setOrderList] = useState();
  const [bookList, setBookList] = useState();
  const [itemList, setItemList] = useState();
  const [optionList, setOptionList] = useState();

  const [itemListCompressed, setItemListCompressed] = useState();

  const [orderDetail, setOrderDetail] = useState(null);

  const [reload, setReload] = useState();

  const clickedDetail = (orderid) => {
    setOrderDetail(orderid);
  };

  useEffect(() => {
    getUnFinishedOrders();
    getUnfinishedBookings();
    getUnfinishedItemsCompressed();
    getUnfinishedItems();
    getUnfinishedItemsOptions();
  }, []);

  useEffect(() => {
    getUnFinishedOrders();
    getUnfinishedBookings();
    getUnfinishedItemsCompressed();
    getUnfinishedItems();
    getUnfinishedItemsOptions();
  }, [controlObject.orderupdate, reload]);

  let getUnFinishedOrders = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          sortASC: true,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOrderList(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getUnfinishedItems = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setItemList(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getUnfinishedItemsOptions = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items-options/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOptionList(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getUnfinishedItemsCompressed = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      let ulist = [];
      data.map((item, index) => {
        if (
          ulist.filter((ulitem) => ulitem.product === item.product).length > 0
        ) {
          let index = ulist.findIndex(
            (ulitem) => ulitem.product == item.product
          );
          let existingitem = ulist[index];
          existingitem.quantity = existingitem.quantity + item.quantity;
        } else {
          ulist.push(item);
        }
      });
      setItemListCompressed(ulist);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getUnfinishedBookings = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/bookings/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setBookList(data);
      console.log("booklist:", data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  return (
    <div>
      {controlObject.webshop.ref !== "" &&
        orderList &&
        itemList &&
        optionList &&
        itemListCompressed &&
        bookList && (
          <>
            {/* --------------- Pickup orders ---------------  */}
            <div className="ocsubtitle">
              <button
                className="ocsubtitle btn btn-success"
                onClick={() =>
                  controlObject.setorderupdate(!controlObject.orderupdate)
                }
              >
                Pickup orders
              </button>
            </div>

            {shopObject.locs &&
              shopObject.locs.map((loc, index) => {
                return (
                  <Fragment key={index}>
                    {bookList &&
                      bookList.filter((book) => book.location === loc.name)
                        .length > 0 && (
                        <>
                          <div>
                            <div className="ocitemlocationline">
                              -- <strong>{loc.name}</strong>
                            </div>
                            <div className="octitlerow w-75 mx-auto ocitemorderline">
                              <div className="occoll">Ref</div>
                              <div className="occols">D Left</div>
                              <div className="occolm">Value (inc.VAT)</div>
                              <div className="occoll">Shipmethod</div>
                              <div className="occoll">Days since order</div>
                              <div className="occoll">Pickup/Arrival</div>
                              <div className="occoll">Orderdate </div>
                            </div>
                            {console.log("booklist:", bookList)}
                            {bookList
                              .filter((book) => book.location === loc.name)
                              .sort(
                                (a, b) =>
                                  new Date(a.date).getTime() +
                                  getMills(a.slotfrom) -
                                  (new Date(b.date).getTime() +
                                    getMills(b.slotfrom))
                              )
                              .map((book, index2) => {
                                return (
                                  <div key={index2} className="posorderitem">
                                    {console.log("book:", book)}
                                    <div className="w-75 mx-auto posorderbooking">
                                      <Bookingline booking={book} />
                                    </div>
                                    {orderList.length > 0 &&
                                      orderList
                                        .filter((wo) => wo.booking === book.id)
                                        .map((order, index3) => (
                                          <Fragment key={index3}>
                                            <div className="w-75 mx-auto">
                                              <OrderLine
                                                order={order}
                                                clickedDetail={clickedDetail}
                                                orderDetail={orderDetail}
                                                setOrderDetail={setOrderDetail}
                                              />
                                            </div>
                                            {/* ------ Cooktimer ------ */}
                                            {shopObject.shopoptions
                                              .usecooktimer && (
                                              <div className="occookcounter">
                                                <CookCounter
                                                  counterOrder={order}
                                                  reload={reload}
                                                  setReload={setReload}
                                                />
                                              </div>
                                            )}
                                            <div className="mx-auto">
                                              <table className="table-sm w-75 mx-auto">
                                                <thead>
                                                  <tr className="positemline">
                                                    <th
                                                      scope="col"
                                                      className="col-3"
                                                    >
                                                      Name
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="col-3"
                                                    >
                                                      Description
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="col-1"
                                                    >
                                                      SKU
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="col-2"
                                                    >
                                                      Quantity
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="col-2"
                                                    >
                                                      x weight
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {order.items.map(
                                                    (orderitem, index4) => {
                                                      let matchingitem =
                                                        itemList.filter(
                                                          (it) =>
                                                            it.id === orderitem
                                                        )[0];

                                                      return (
                                                        <Fragment key={index4}>
                                                          {matchingitem && (
                                                            <>
                                                              <ItemLine
                                                                item={
                                                                  matchingitem
                                                                }
                                                                optionList={
                                                                  optionList
                                                                }
                                                              />
                                                            </>
                                                          )}
                                                        </Fragment>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Fragment>
                                        ))}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                  </Fragment>
                );
              })}
            {/* --------------- Mail orders ---------------  */}
            <div className="ocsubtitle">
              <button
                className="ocsubtitle btn btn-success"
                onClick={() =>
                  controlObject.setorderupdate(!controlObject.orderupdate)
                }
              >
                Mail orders
              </button>
            </div>
            {orderList
              .filter((wo) => wo.shipmethod === "M")
              .map((order, index3) => (
                <Fragment key={index3}>
                  <div className="ocitemorderline">
                    <OrderLine
                      order={order}
                      clickedDetail={clickedDetail}
                      orderDetail={orderDetail}
                      setOrderDetail={setOrderDetail}
                    />
                  </div>
                  <div className="mx-auto">
                    <table className="table-sm w-75 mx-auto">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">SKU</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">x weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.items.map((orderitem, index4) => {
                          let matchingitem = itemList.filter(
                            (it) => it.id === orderitem
                          )[0];

                          return (
                            <Fragment key={index4}>
                              {matchingitem && (
                                <>
                                  <ItemLine
                                    item={matchingitem}
                                    optionList={optionList}
                                  />
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* <div>
                    {order.items.map((orderitem, index4) => {
                      let matchingitem = itemList.filter(
                        (it) => it.id === orderitem
                      )[0];

                      return (
                        <div key={index4}>
                          {matchingitem && (
                            <>
                              <ItemLine
                                item={matchingitem}
                                optionList={optionList}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div> */}
                </Fragment>
              ))}
          </>
        )}
    </div>
  );
};

export default POSOrderItemList;
