import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AuthContext from "../Store/auth-context";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";
import CLine from "../Components/CLine";
import getTitle from "../Functions/getTitle";
import CreateInvCorrAddress from "../Components/CreateInvCorrAddress";
import CreateOrg from "../Components/CreateOrg";

const WelcomePage = () => {
  let authObject = useContext(AuthContext);
  let controlObject = useContext(ControlContext);
  let shopObject = useContext(ShopContext);
  const [orderInfo, setOrderInfo] = useState();

  let getGeneralOrderInfo = async (webshop) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/order/info/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshop,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOrderInfo(JSON.parse(data));
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getGeneralInfo = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/general/info/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      console.log("data retrieved unparsed :", data);
      console.log("data retrieved parsed :", JSON.parse(data));
    } else {
      alert("something went wrong checking your details");
    }
  };

  const startPayment = () => {
    console.log("clicked");
  };

  useEffect(() => {
    if (controlObject.webshop.id !== undefined) {
      getGeneralOrderInfo(controlObject.webshop.id);
      console.log("general info:", getGeneralInfo());
    }
  }, [controlObject.webshop.id]);

  return (
    <>
      <Helmet>
        <title>Ubicopia® - Welcome user</title>
        <meta name="description" content="Welcomepage for registered users" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {authObject.user && authObject.user.blocked && (
        <div className="text-white">
          <div className="fs-3">
            Momentarily manual release will be required.
          </div>
          <p>You will be contacted shortly</p>
        </div>
      )}
      {authObject.user && !authObject.user.blocked && (
        <>
          {authObject.user && authObject.organisation && (
            <div className="cpage">
              {authObject.user && (
                <>
                  <div className="ctitle">Welcome</div>
                  <div className="cblock">
                    <div className="csubtitle">Your user details</div>
                    <CLine t="title" v={getTitle(authObject.user.title)} />
                    <CLine t="First Name" v={authObject.user.firstname} />
                    <CLine t="Name" v={authObject.user.name} />
                    <CLine t="Phone" v={authObject.user.phone} />
                    <CLine t="Email" v={authObject.user.email} />
                  </div>
                </>
              )}
              {authObject.user.organisation && (
                <>
                  <div className="cblock">
                    <div className="csubtitle">Your organisation</div>
                    <CLine t="name" v={authObject.organisation.name} />
                    <CLine t="VAT" v={authObject.organisation.vat} />
                    <CLine t="Bank" v={authObject.organisation.bankiban} />
                    <CLine t="BIC" v={authObject.organisation.bankbic} />
                  </div>
                </>
              )}
              {!authObject.user.organisation && (
                <>
                  <CreateOrg user={authObject.user} />
                </>
              )}
              {}
              {/* {authObject.user.organisation &&
                (!authObject.user.organisation.smallogo ||
                  !authObject.user.organisation.smallogo) && (
                  <>
                    <UpdateOrgWithLogo user={authObject.user} />
                  </>
                )} */}
              {authObject.user.organisation &&
                !authObject.organisation.invoice_address && (
                  <>
                    <CreateInvCorrAddress
                      user={authObject.user}
                      organisation={authObject.organisation}
                    />
                  </>
                )}
              {authObject.user &&
                authObject.organisation &&
                authObject.organisation.invoice_address &&
                controlObject.webshoplist &&
                !controlObject.webshop.organisation && (
                  <div className="cblock">
                    <div className="csubtitle">
                      {console.log("webshop:", controlObject.webshop)}
                      Please proceed to select your webservice from the dropdown
                      !
                    </div>
                  </div>
                )}
              {orderInfo && controlObject.webshop && (
                <>
                  <div className="cblock">
                    <div className="csubtitle">Action required</div>
                    <CLine t="Orders pending" v={orderInfo.openorders} />
                    <CLine
                      t="Items to pre-order"
                      v={orderInfo.uncovereditems}
                    />
                  </div>
                  <div className="cblock">
                    <div className="csubtitle">Shop Statistics</div>
                    <CLine t="Total orders so far" v={orderInfo.qtyorders} />
                    <CLine
                      t={
                        "Total turnover (" +
                        controlObject.webshop.currency +
                        ")"
                      }
                      v={orderInfo.totalordervalue.toFixed(2)}
                    />

                    <CLine t="# users" v={orderInfo.totalusers} />
                    <CLine t="# products" v={orderInfo.totalproducts} />
                    <div className="ms-3 p-2 border">
                      <strong>Orders by month</strong> - date:{" "}
                      {new Date().getDate() +
                        "/" +
                        (new Date().getMonth() + 1) +
                        "/" +
                        new Date().getFullYear()}
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">current month</th>
                            <th scope="col">previous month</th>
                            <th scope="col">2 months ago</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{orderInfo.ordersthismonth}</td>
                            <td>{orderInfo.ordersmin1month}</td>
                            <td>{orderInfo.ordersmin2month}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WelcomePage;
