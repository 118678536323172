import React, { useState, useContext, useEffect, Fragment } from "react";
import AuthContext from "../Store/auth-context";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";

import AddNewsletter from "./AddNewsletter";
import EditNewsletter from "./EditNewsLetter";
import DeleteNewsletter from "./DeleteNewsLetter";

import AddPromoLetter from "./AddPromoLetter";
import EditPromoLetter from "./EditPromoLetter";
import DeletePromoLetter from "./DeletePromoLetter";

const CustomerMailTemplates = () => {
  const authObject = useContext(AuthContext);
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);

  const [newsLetters, setNewsLetters] = useState([]);
  const [promoLetters, setPromoLetters] = useState([]);

  // togglers
  const [addNews, setAddNews] = useState(false);
  const [addPromo, setAddPromo] = useState(false);
  const [editNews, setEditNews] = useState(null);
  const [editPromo, setEditPromo] = useState(null);
  const [delNews, setDelNews] = useState(null);
  const [delPromo, setDelPromo] = useState(null);
  const [confirmProduction, setConfirmProduction] = useState(null);

  useEffect(() => {
    getNewsLetters();
    getPromoLetters();
    shopObject.getproductlist();
  }, [controlObject.webshop]);

  useEffect(() => {
    getNewsLetters();
  }, [, editNews === null, delNews === null, addNews === false]);

  useEffect(() => {
    getPromoLetters();
  }, [, editPromo === null, delPromo === null, addPromo === false]);

  let getNewsLetters = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/newsletter/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({ webshopid: controlObject.webshop.id }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setNewsLetters(data);
    } else {
      alert("something went wrong checking your newsletters");
    }
  };

  let getPromoLetters = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/promoletter/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({ webshopid: controlObject.webshop.id }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setPromoLetters(data);
    } else {
      alert("something went wrong getting your promoletters");
    }
  };

  let sendNewsLetterTestMail = async (newsletter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/newsletter/test/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          newsletterid: newsletter.id,
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("newsletter test went OK");
    } else {
      alert("sendtestmail NOK");
    }
  };

  let sendNewsLetterProductionMail = async (newsletter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/newsletter/publish/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          newsletterid: newsletter.id,
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("newsletter publish went OK");
    } else {
      alert("sendtestmail NOK");
    }
  };

  let sendPromoLetterTestMail = async (promoletter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/promoletter/test/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          promoletterid: promoletter.id,
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("promo test went OK");
    } else {
      alert("send promo testmail NOK");
    }
  };

  let sendPromoLetterProductionMail = async (promoletter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/promoletter/publish/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          promoletterid: promoletter.id,
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("promoletter publish went OK");
    } else {
      alert("promoletter publish NOK");
    }
  };

  function runNewsLetterTest(nl) {
    console.log("clicked");
    sendNewsLetterTestMail(nl);
    // sendTestMail();
  }

  function runNewsLetterProduce(nl) {
    console.log("clicked");
    sendNewsLetterProductionMail(nl);
    setConfirmProduction(null);
    // sendTestMail();
  }

  function runPromoLetterTest(nl) {
    console.log("clicked");
    sendPromoLetterTestMail(nl);
    // sendTestMail();
  }

  function runPromoLetterProduce(nl) {
    console.log("clicked");
    sendPromoLetterProductionMail(nl);
    // sendTestMail();
  }

  return (
    <>
      <div>
        <div className="ccsubtitle">Marketing mailings</div>
      </div>
      <h4 className="col-8">Newsletters</h4>

      {addNews && (
        <>
          <AddNewsletter
            setToggler={setAddNews}
            organisation={authObject.organisation}
            webshop={controlObject.webshop}
          />
        </>
      )}
      {editNews === null && delNews === null && addNews === false && (
        <>
          <div className="row mt-3">
            <div className="col-4 fs-5">List of NewsLetters</div>
            <button
              className="btn btn-primary btn-sm col-2 ms-4"
              onClick={() => setAddNews(true)}
            >
              Add a newsletter
            </button>
          </div>
        </>
      )}

      {newsLetters &&
        editNews === null &&
        delNews === null &&
        addNews === false && (
          <>
            {newsLetters.map((nl, index) => {
              return (
                <Fragment key={index}>
                  <div className="row mt-3">
                    <div className="col-4 ms-3" key={index}>
                      {nl.name}
                    </div>
                    <button
                      className="btn btn-primary btn-sm col-1 ms-3"
                      onClick={() => setEditNews(nl)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm col-1 ms-3"
                      onClick={() => setDelNews(nl)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-success btn-sm col-1 ms-5"
                      onClick={() => runNewsLetterTest(nl)}
                    >
                      Test
                    </button>
                    {confirmProduction === null && (
                      <>
                        <button
                          className="btn btn-success btn-sm col-1 ms-3"
                          onClick={() => setConfirmProduction(nl)}
                        >
                          Produce
                        </button>
                      </>
                    )}
                    {confirmProduction === nl && (
                      <>
                        <button
                          className="btn btn-success btn-sm col-1 ms-3"
                          onClick={() => runNewsLetterProduce(nl)}
                        >
                          Confirm Produce
                        </button>
                      </>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </>
        )}

      {editNews !== null && (
        <>
          <EditNewsletter setToggler={setEditNews} newsLetter={editNews} />
        </>
      )}

      {delNews !== null && (
        <>
          <DeleteNewsletter setToggler={setDelNews} newsLetter={delNews} />
        </>
      )}

      {/* -------- Promoletter section - still needs development -------- */}
      {/* <h4 className="mt-5">Product promotions</h4>
      <div className="row mt-3">
        <div className="col-4 fs-5">List of PromoLetters</div>
        <button
          className="btn btn-primary btn-sm col-2 ms-4"
          onClick={() => setAddPromo(true)}
        >
          Add a promoletter
        </button>
      </div>
      {addPromo && (
        <>
          <AddPromoLetter
            setToggler={setAddPromo}
            organisation={authObject.organisation}
            webshop={controlObject.webshop}
            productList={shopObject.products}
          />
        </>
      )}
      {promoLetters &&
        editPromo === null &&
        delPromo === null &&
        addPromo === false && (
          <>
            {promoLetters.map((pl, index) => {
              return (
                <Fragment key={index}>
                  <div className="row mt-3">
                    <div className="col-4 ms-3" key={index}>
                      {pl.name}
                    </div>
                    <button
                      className="btn btn-primary btn-sm col-1 ms-3"
                      onClick={() => setEditPromo(pl)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm col-1 ms-3"
                      onClick={() => setDelPromo(pl)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-success btn-sm col-1 ms-5"
                      onClick={() => runPromoLetterTest(pl)}
                      disabled
                    >
                      Test
                    </button>
                    <button
                      className="btn btn-success btn-sm col-1 ms-3"
                      onClick={() => runPromoLetterProduce(pl)}
                      disabled
                    >
                      Produce
                    </button>
                  </div>
                </Fragment>
              );
            })}
          </>
        )}

      {editPromo !== null && (
        <EditPromoLetter
          setToggler={setEditPromo}
          promoLetter={editPromo}
          productList={shopObject.products}
        />
      )}

      {delPromo !== null && (
        <DeletePromoLetter setToggler={setDelPromo} promoLetter={delPromo} />
      )} */}
    </>
  );
};

export default CustomerMailTemplates;
